import "./prices.css"

import axios from "axios"
import React, { useRef, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Site, SiteCardSmall, SiteCardDetailed } from "@/components/site-card"
import { parse, addDays, subDays } from 'date-fns'

import { Coin } from '@/components/coin-card'
import { CoinGeckoClient, CoinMarket } from "coingecko-api-v3-axios"

import Layout from "@/templates/layout"
import FAQs from "@/components/faqs"
import MediaObject from "@/components/media-object"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import AuthorBlock from "@/components/author-block"

import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

interface Series {
  symbol: string
  name: string
  priceCurrency: string
  supplyCurrency: string
  data: {
    date: string
    price: number
    supply: number
  }[]
}

const PriceChart = (props: { prices: Record<string, Series> }) => {
  const { prices } = props
  const series = []
  const [timeframe, setTimeframe] = useState<number>(99999)
  const timeframeOptions = [
    { label: "7d", value: 7 },
    { label: "30d", value: 30 },
    { label: "90d", value: 90 },
    { label: "180d", value: 180 },
    { label: "1y", value: 365 },
    { label: "max", value: 99999 },
  ]

  let i = 0
  for (const key in prices) {
    if (++i > 10) { break; }
    const coinData = prices[key]
    const _data = coinData.data
      .slice(0, timeframe)
      .map(row => ({
        date: parse(row.date, "yyyyMMdd", new Date()),
        price: row.price,
        supply: row.supply,
      }))
    const data = _data.map((row, i) => ([row.date.getTime(), row.price]))
    series.push({ type: "line", name: coinData.name, data, })
  }

  const chartOptions: Highcharts.Options = {
    lang: {
      decimalPoint: ".",
      thousandsSep: ",",
    },
    title: {
      text: "Coin Price"
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      labels: {
        padding: 10,
        autoRotation: [-45],
        formatter: function() {
          // @ts-ignore
          return Highcharts.dateFormat('%b/%e/%Y', this.value);
        }
      }
    },
    yAxis: {
      title: {
        text: "Price"
      }
    },
    series: series as any,
    chart: {},
  }

  return <div>
    <div className="radio-buttons"
          onChange={(ev) => setTimeframe((ev.target as any).value)}>
      {timeframeOptions.map((option) => <label>
        <input type="radio" name="market-days" value={option.value}
          defaultChecked={option.value === timeframe}
        />
        <span>{option.label}</span>
      </label>)}
    </div>
    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
  </div>
}

const InflationChart = (props: { prices: Record<string, Series> }) => {
  const { prices } = props
  const series = []
  const [timeframe, setTimeframe] = useState<number>(99999)
  const timeframeOptions = [
    { label: "7d", value: 7 },
    { label: "30d", value: 30 },
    { label: "90d", value: 90 },
    { label: "180d", value: 180 },
    { label: "1y", value: 365 },
    { label: "max", value: 99999 },
  ]

  const [marketData, setMarketData] = useState<"24h" | "7d" | "1y" | "daily">("7d")
  const marketDataOptions = [
    { label: "24H Annualized", value: "24h" },
    { label: "7D Annualized", value: "7d" },
    { label: "1Y Actual Inflation", value: "1y" },
    { label: "Daily Issuance", value: "daily" },
  ]

  let i = 0
  for (const key in prices) {
    if (++i > 10) { break; }
    const coinData = prices[key]

    const _data = coinData.data
      .slice(0, timeframe)
      .map((row, i) => ({
        date: parse(row.date, "yyyyMMdd", new Date()),
        price: row.price,
        supply: row.supply,
      }))
    const dataObj = Object.fromEntries(_data.map(row => [row.date.getTime(), row]))

    const data = _data.map((row, i) => {
      let now = 0
      let then = 0
      let value = null
      let row2
      const date = row.date
      switch (marketData) {
        case "24h":
          now = _data[i].supply
          row2 = dataObj[subDays(date, 1).getTime()]
          if (row2) {
            then = row2.supply
            value = Math.pow(1 + (now - then) / then, 365.00) - 1
            if (value > 80 || value < -80) {
              value = null
            }
            if (now < 0.05 * then || then < 0.05 * now) {
              value = null
            }
          }
          break
        case "7d":
          now = _data[i].supply
          row2 = dataObj[subDays(date, 7).getTime()]
          if (row2) {
            then = row2.supply
            value = Math.pow(1 + (now - then) / then, 52.1428) - 1
            if (value > 80 || value < -80) {
              value = null
            }
            if (now < 0.05 * then || then < 0.05 * now) {
              value = null
            }
          }
          break
        case "1y":
          now = _data[i].supply
          row2 = dataObj[subDays(date, 365).getTime()]
          if (row2) {
            then = row2.supply
            if (now && then) {
              value = 100 * (now - then) / then
              if (value > 80 || value < -80) {
                value = null
              }
            } else {
              value = null
            }
          } else {
            value = null
          }
          break
        case "daily":
          now = _data[i].supply
          row2 = dataObj[subDays(date, 1).getTime()]
          if (row2) {
            then = row2.supply
            if (now && then) {
              value = (now - then) * row2.price
              if (now < 0.05 * then || then < 0.05 * now) {
                value = null
              }
            } else {
              value = null
            }
          }
          break
      }
      return [ date.getTime(), value ]
    })

    series.push({ type: "line", name: coinData.name, data: data, })
  }

  const chartOptions: Highcharts.Options = {
    lang: {
      decimalPoint: ".",
      thousandsSep: ",",
    },
    title: {
      text: "Coin Inflation"
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      labels: {
        padding: 10,
        autoRotation: [-45],
        formatter: function() {
          // @ts-ignore
          return Highcharts.dateFormat('%b/%e/%Y', this.value);
        }
      }
    },
    yAxis: {
      type: marketData === "daily" ? "logarithmic" : "linear",
      title: {
        text: "Inflation"
      }
    },
    series: series as any,
    chart: {},
  }

  return <div>
    <div className="radio-buttons"
          onChange={(ev) => setTimeframe((ev.target as any).value)}>
      {timeframeOptions.map((option) => <label>
        <input type="radio" name="market-days" value={option.value}
          defaultChecked={option.value === timeframe}
        />
        <span>{option.label}</span>
      </label>)}
    </div>
    <div className="radio-buttons"
          onChange={(ev) => setMarketData((ev.target as any).value)}>
      {marketDataOptions.map((option) => <label>
        <input type="radio" name="market-metric" value={option.value}
          defaultChecked={option.value === marketData}
        />
        <span>{option.label}</span>
      </label>)}
    </div>
    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
  </div>
}

const CirculatingSupplyChart = (props: { prices: Record<string, Series> }) => {
  const { prices } = props
  const series = []
  let i = 0

  const [timeframe, setTimeframe] = useState<number>(99999)
  const timeframeOptions = [
    { label: "7d", value: 7 },
    { label: "30d", value: 30 },
    { label: "90d", value: 90 },
    { label: "180d", value: 180 },
    { label: "1y", value: 365 },
    { label: "max", value: 99999 },
  ]

  for (const key in prices) {
    if (++i > 1) { break; }
    const coinData = prices[key]
    const _data = coinData.data
      .slice(0, timeframe)
      .map(row => {
      return {
        date: parse(row.date, "yyyyMMdd", new Date()),
        price: row.price,
        supply: row.supply,
      }
    })
    const data = _data.map((row, i) => { 
      let supply = row.supply
      return [row.date.getTime(), supply]
    })
    series.push({ type: "line", name: coinData.name, data, })
  }

  const chartOptions: Highcharts.Options = {
    lang: {
      decimalPoint: ".",
      thousandsSep: ",",
    },
    title: {
      text: "Circulating Supply"
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      labels: {
        padding: 10,
        autoRotation: [-45],
        formatter: function() {
          // @ts-ignore
          return Highcharts.dateFormat('%b/%e/%Y', this.value);
        }
      }
    },
    yAxis: {
      type: 'logarithmic',
      title: {
        text: "Circulating Supply"
      }
    },
    series: series as any,
    chart: {},
  }

  return <div>
    <div className="radio-buttons"
          onChange={(ev) => setTimeframe((ev.target as any).value)}>
      {timeframeOptions.map((option) => <label>
        <input type="radio" name="market-days" value={option.value}
          defaultChecked={option.value === timeframe}
        />
        <span>{option.label}</span>
      </label>)}
    </div>
    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
  </div>
}

const HeroUnit = ({ page }: { page: any }) => {
  const image = {
    data: page.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: page.featuredImage?.node?.alt || ``,
  }
  const media = image?.data && <GatsbyImage image={image.data} alt={image.alt} />

  return <>
    <MediaObject className="order-reverse" media={media}>
      <h1>{page.title}</h1>
      <AuthorBlock author={page.author.node} modified={page.modified} />
    </MediaObject>
  </>
}

function formatMoney(amount: number): string {
  const str = amount.toString()
  const parts = str.split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  parts[1] = (parts[1] || "").slice(0, 2)
  return parts.join(".")
}

function CoinTable (props: { index: number, coin: Coin, market: CoinMarket }) {
  const { t } = useTranslation()
  const { index, coin, market } = props
  if (!coin) { return <></> }
  if (!market) { return <></> }

  return <div className="table-row coin-info">
    <Link to={`/coins/${coin.slug}/`} className="column coin-index">
      {index}
    </Link>
    <Link to={`/coins/${coin.slug}/`} className="column coin-name">
      <img src={market.image} width="50" height="50" />
      <span>{market.name}</span>
    </Link>
    <div className="column coin-price">
      ${formatMoney(market.current_price || 0)}
    </div>
    <div className={`column coin-price-change hidden-mobile ${market.price_change_percentage_24h! > 0 ? 'column-positive' : 'column-negative'}`}>
      {market.price_change_percentage_24h?.toFixed(2)}%
    </div>
    <div className="column coin-market-cap hidden-mobile">
      ${formatMoney(market.market_cap|| 0)}
    </div>
    <div className="column coin-staking-reward hidden-mobile">
      {coin.coinFields.stakingReward}
    </div>
    <Link to={`/coins/${coin.slug}/`} className="column coin-link">
      {t('Buy Now')}
    </Link>
  </div>
}

const Index = (props: any) => {
  const { page, sites } = props.data
  if (!page) { return <></> }
  const [prices, setPrices] = useState<Record<string, Series>>({})

  React.useEffect(() => {
    axios.get("/prices.json").then(response => {
      setPrices(response.data)
    })
  }, [])

  const { t } = useTranslation()
  
  if (typeof window !== 'undefined') {
    Highcharts.setOptions({
      lang: {
        decimalPoint: ".",
        thousandsSep: ",",
      },
    })
  }


  const seo = {
    title: page.seoTitle,
    description: page.seoDescription,
    schema: page.seoSchema,
    url: "/exchanges",
  }

  return <Layout {...props} className="page-prices" seo={seo} title={t("Crypto Exchanges")}>
    <HeroUnit page={page} />
    <hr />
  
    <div className="container container-columns">
      <div className="column">
        <h1>Crypto Inflation and Issuance</h1>
        <p>
        </p>
        <h2>Crypto Inflation Chart - top 10</h2>
        <PriceChart prices={prices} />
        <CirculatingSupplyChart prices={prices} />
        <InflationChart prices={prices} />

        <h2>Crypto Inflation and Issuance per Coin</h2>
        <FAQs />
      </div>
    </div>
  
  </Layout>
}

const SiteListWidget = (props: { className: string, sites: { nodes: Site[] } }) => {
  const { t } = useTranslation()
  const classNames = ['widget']
  if (props.className) { classNames.push(props.className) }

  return <div className={classNames.join(" ")}>
    <h3 dangerouslySetInnerHTML={{__html: t('best-crypto-exchanges')}} />
    <div className="site-list">
      {props.sites
        .nodes
        .sort((a, b) => a.menuOrder - b.menuOrder)
        .slice(0, 3)
        .map((site, i) => <SiteCardSmall key={i} site={site} />)}
    </div>
  </div>
}

const SiteListDetailed = (props: { sites: { nodes: Site[] } }) => {
  return <div className="site-list-detailed">
    <div className="site-list">
      {props.sites
        .nodes
        .sort((a, b) => a.menuOrder - b.menuOrder)
        .map((site, i) => <SiteCardDetailed key={i} site={site} />)}
    </div>
  </div>
}

export default Index

export const query = graphql`
  query ($language: String!) {
    page: wpPage(slug: { eq: "exchanges" }, locale: { locale: { eq: $language} }) {
      ...PageInformation
    }
    sites: allWpSite(filter: { locale: { locale: { eq: $language } } }) {
      nodes {
        ...SiteInformation
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
